import React from "react";
import Crd from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

interface CardProps {
  title: string;
  content: string;
  uri: string;
}

const Card: React.FC<CardProps> = ({ title, content, uri }) => {
  return (
    <RouterLink to={uri} style={{ textDecoration: "none", color: "inherit" }}>
      <Crd
        sx={{
          border: "1px solid grey",
          display: "flex",
          flexDirection: "column",
          margin: "10px",
          height: "150px",
          width: "270px",
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h5" component="div" noWrap>
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ whiteSpace: "pre-wrap" }}
          >
            {content}
          </Typography>
        </CardContent>
      </Crd>
    </RouterLink>
  );
};

export default Card;
