import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from "../../images/logo_white.png";
import SignInSignOutButton from "./SignInSignOutButton";
import WelcomeName from "./WelcomeName";
import CountryDropdown from "./CountryDropdown";

function ResponsiveAppBar() {
  const navigate = useNavigate();

  return (
    // TODO understand why margins are not working (class header)
    <>
      <AppBar position="static" className="header" elevation={0}>
        {/* <Container > */}
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              height: { xs: 30, sm: 40, md: 50 }, // Adjust height based on screen size
              marginLeft: "2%", // Adjust margin based on screen size
            }}
            alt="Simplecheck logo."
            src={Logo}
            // on click redirect to /
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />

          <Box
            sx={{
              flexGrow: 1,
              maxWidth: "100%",
              mr: "1%",
              ml: "3%",
              // add white letter style always
              ".MuiFilledInput-root": {
                backgroundColor: "#FFFF", // change background color to white
              },

              // add white style background when element is hovered
              ".MuiFilledInput-root:hover": {
                backgroundColor: "#FFFF", // change background color to white
              },

              // add white style background when element is focused
              ".MuiFilledInput-root.Mui-focused": {
                backgroundColor: "#FFFF", // change background color to white
              },
              ".MuiFilledInput-input": {
                color: "#727272", // change text color to grey
              },
            }}
          >
            {/* <TextField
              fullWidth
              size="small"
              label={i18next.t("b2c.header.searchMessage")}
              id="fullWidth"
              variant="filled"
            /> */}
          </Box>

          <CountryDropdown />

          <Box
            sx={{
              flexGrow: 0,
              marginRight: "1%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <WelcomeName />
            <SignInSignOutButton />
          </Box>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      <Outlet />
    </>
  );
}
export default ResponsiveAppBar;
