import React, { useState } from "react";
import { TextField, SelectChangeEvent } from "@mui/material";
import validateInput, {
  ValidateInputTestType,
} from "../../utils/validateInput";
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@mui/material";

interface ValidatedTextFieldProps {
  defaultValue: any;
  type: string;
  filteredTestVR: ValidateInputTestType | null;
  handleResultChange: (newValue: any) => any;
}

const ValidatedTextField = ({
  defaultValue,
  filteredTestVR,
  type,
  handleResultChange,
}: ValidatedTextFieldProps) => {
  const { t } = useTranslation();
  const [color, setColor] = useState<
    "primary" | "error" | "secondary" | "info" | "success" | "warning"
  >("primary");
  const [variant, setVariant] = useState<"outlined" | "standard" | "filled">(
    "standard",
  );
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState(
    t("b2b.validatedTextField.typeResult"),
  );

  const handleInputChanged = (
    e:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => {
    let transformedTestInput = e.target.value;

    // transformation comma to dot only necessary for numerical results in  (FHIR does not accept ,)
    if (type !== "string" && transformedTestInput) {
      transformedTestInput = transformedTestInput.replace(/,/g, ".");
    }

    const [isValid, isOutOfRange] = validateInput(
      transformedTestInput,
      type,
      filteredTestVR,
    );

    if (!isValid) {
      setColor("error");
      setVariant("standard");
      setError(true);
      setHelperText(t("b2b.validatedTextField.invalidInput"));
      handleResultChange(undefined);
      return;
    }

    if (filteredTestVR && isValid && isOutOfRange) {
      // if value is valid but out of range, set color to error
      setColor("error");
      setError(true);
      setHelperText(
        (filteredTestVR.minNumeric && String(filteredTestVR.minNumeric) !== ""
          ? ` Min: ${filteredTestVR.minNumeric}`
          : "") +
          (filteredTestVR.maxNumeric && String(filteredTestVR.maxNumeric) !== ""
            ? ` Max: ${filteredTestVR.maxNumeric}`
            : ""),
      );
    } else {
      // if value is valid and not out of range, set color to primary
      setError(false);
      setColor("primary");
      setHelperText("");
    }

    if (isValid && !isOutOfRange) {
      setError(false);
    }

    // this is needed to warn the user that the result is valid but no reference range was found
    // it needs to come after the other checks as we have a previous default after default is valid and not out of range
    if (!filteredTestVR && isValid) {
      setColor("warning");
      setError(false);
      setHelperText(t("b2b.validatedTextField.noReferenceRange"));
    }

    if (isValid) {
      handleResultChange(transformedTestInput);
    }
  };

  return (
    <>
      {type === "string" ? (
        <Select
          value={defaultValue}
          onChange={(e) => {
            handleInputChanged(e);
          }}
          style={{
            maxWidth: "250px",
            fontSize: "0.7rem",
            // change text color to red if out of range
            color: error ? "red" : "black",
          }}
        >
          <MenuItem value={filteredTestVR?.textNegative || ""}>
            {filteredTestVR?.textNegative || ""}
          </MenuItem>
          <MenuItem value={filteredTestVR?.textPositive || ""}>
            {filteredTestVR?.textPositive || ""}
          </MenuItem>
        </Select>
      ) : (
        <TextField
          defaultValue={defaultValue}
          color={color}
          variant={variant}
          onBlur={handleInputChanged}
          error={error}
          helperText={helperText}
          size={"small"}
          focused
        />
      )}
    </>
  );
};

export default ValidatedTextField;
