import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

const WelcomeName = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [name, setName] = useState<string | null>(null);
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (activeAccount && activeAccount.name) {
      setName(t("b2c.welcomeName") + activeAccount.name.split(" ")[0]);
    } else {
      setName(null);
    }
  }, [activeAccount, t]);

  if (name) {
    return (
      <Typography
        variant="h3"
        sx={{ fontSize: { xs: "0.8rem", sm: "1.25rem", md: "1.5rem" } }}
      >
        {name}
      </Typography>
    );
  } else {
    return null;
  }
};

export default WelcomeName;
