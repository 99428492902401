import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  selectPatients,
  selectChoosePatient,
  addObservation,
  addEncounter,
  removeAllObservationsForPatient,
  ReferenceRange,
} from "../../../store/patientB2BSlice";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import PatientList from "../../../components/b2b/PatientList";
import Fuse from "fuse.js";
import ObservationList from "../../../components/shared/ObservationList";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { searchFHIRResource } from "../../../services/api";
import { handleSpecimenRequest } from "../../../services/fhir";
import { callApi } from "../../../services/MsalApiCall";

// from /public, import the JSON file vr_test_filtered.json
import testIdentifier from "../../../data/vr_test_filtered.json";
import testAttributes from "../../../data/vr_test.json";
import BRTipoAmostra from "../../../types/fhir/BRTipoAmostra.json";
import ReferenceRangeTypeCustomCodes from "../../../types/fhir/ReferenceRangeTypeCustomCodes.json";
import { Tests, TestVR } from "../../../types/reference_values";
import { useTranslation } from "react-i18next";
import { convertFhirToObservation } from "../../../types/fhir/observation";
import { convertToDateTimeLocal } from "../../../utils/convertToDateTimeLocal";
import { Specimen } from "../../../store/patientB2BSlice";
import CircularProgress from "@mui/material/CircularProgress";

export default function AddObservation() {
  const { t } = useTranslation();
  const patients = useAppSelector(selectPatients);
  const [searchQuery, setSearchQuery] = useState<any>("");
  const selectedPatient = useAppSelector(selectChoosePatient);
  const patient = patients[selectedPatient];
  const navigate = useNavigate();
  const [effectiveDateTime, setEffectiveDateTime] = useState<string>("");
  const [issuedTime, setIssuedTime] = useState<string>("");
  const [encounterId, setEncounterID] = useState<string>("");
  const [periodStart, setPeriodStart] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const testsVR: Tests = testAttributes as Tests;
  const specimen = BRTipoAmostra.compose.include[0].concept as any;

  const dispatch = useAppDispatch();

  const fuse = new Fuse(testIdentifier, {
    keys: ["LOINC_ID", "INTERNAL_ID", "TEST"],
    includeScore: true,
    threshold: 0.3, // Adjust this value based on your needs
  });

  const filteredTests =
    searchQuery.length > 0
      ? fuse
          .search(searchQuery)
          .slice(0, 3)
          .map((result) => result.item)
      : testIdentifier;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleAddTest = (patientId: string, observationId: string) => {
    const testMetadata = testsVR[observationId] as TestVR[];

    // if encounterID is empty, raise alert and return
    if (!encounterId) {
      alert(t("b2b.addObservation.encounterIDRequired"));
      return;
    }

    const selectedSpecimen = specimen.filter(
      (spec: any) => spec.code === testMetadata[0].SPECIMEN,
    );

    // iterarate over testMetadata to create ReferenceRange[]
    const referenceRange = testMetadata.map((test) => {
      // from ReferenceRangeTypeCustomCodes use test.STATUS to map to statusCode and get statusDisplay
      const status = ReferenceRangeTypeCustomCodes.find(
        (concept) => concept.statusCode === test.STATUS,
      );
      return {
        low: test.VR_MIN,
        high: test.VR_MAX,
        textNegative: test.VR_TEXT_NEGATIVE,
        sex: test.SEX,
        ageMin: test.AGE_MIN,
        ageMax: test.AGE_MAX,
        status: status?.statusDisplay || undefined,
        statusCode: status?.statusCode || undefined,
      } as ReferenceRange;
    });

    dispatch(
      addObservation(patientId, observationId, {
        fhirId: null,
        isNew: true,
        name: testMetadata[0].TEST,
        method: testMetadata[0].METHOD,
        effectiveTime: effectiveDateTime,
        issuedTime: issuedTime,
        specimen: {
          fhirId: selectedSpecimen[0].fhirId,
          code: testMetadata[0].SPECIMEN,
          display: selectedSpecimen[0].display,
        },
        unit: testMetadata[0].UNIT,
        referenceRange: referenceRange,
        type: testMetadata[0].TYPE,
      }),
    );

    setSearchQuery("");
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && filteredTests.length > 0) {
      handleAddTest(selectedPatient, filteredTests[0].LOINC_ID);
    }
  };

  const setCurrentDateTime = () => {
    const currentDateTime = new Date();
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    setEffectiveDateTime(formattedDateTime);
    setIssuedTime(formattedDateTime);
    setPeriodStart(formattedDateTime);
  };

  useEffect(() => {
    setCurrentDateTime();
  }, []);

  // update encounter if time changes or encounterID changes

  const handleEncounterChange = async () => {
    // avoid updating encounter if encounterId is empty
    if (!encounterId) {
      alert(t("b2b.addObservation.encounterIDRequired"));
      return;
    }

    setIsLoading(true);

    try {
      // search for existing encounters in database
      const { accessToken } = await callApi();

      // clear observations for this patient
      dispatch(removeAllObservationsForPatient(selectedPatient));
      setCurrentDateTime();

      const queryString = `identifier=${encounterId}&subject=Patient/${patient.fhirId}`;
      // const queryString = `identifier=${encounterId}`;

      const encounterResponse = await searchFHIRResource(
        `/Encounter?${queryString}`,
        accessToken,
      );

      // if encounter exists, update encounter
      if (encounterResponse && encounterResponse.entry) {
        const encounter = encounterResponse.entry[0].resource;

        // get observations for this encounter
        const observationQueryString = `encounter=Encounter/${encounter.id}`;

        const observationResponse = await searchFHIRResource(
          `/Observation?${observationQueryString}`,
          accessToken,
        );

        if (observationResponse.entry) {
          // Add observations to redux store
          await Promise.all(
            observationResponse.entry.map(async (entry: any) => {
              const { loincId, observation } = convertFhirToObservation(
                entry.resource,
              );

              const specimenResponse = await handleSpecimenRequest(
                observation.specimen as unknown as string,
                "GET",
                accessToken,
              );

              observation.specimen = {
                fhirId: specimenResponse.id,
                code: specimenResponse.type.coding[0].code,
                display: specimenResponse.type.coding[0].display,
              } as Specimen;

              dispatch(
                addObservation(selectedPatient, loincId, {
                  ...observation,
                  isNew: false,
                }),
              );
            }),
          );
        }

        dispatch(
          addEncounter({
            fhirId: encounter.id,
            patientFhirId: patient.fhirId,
            isNew: false,
            encounterId: encounterId,
            periodStart: convertToDateTimeLocal(encounter.period.start),
            periodEnd: convertToDateTimeLocal(encounter.period.end),
          }),
        );
        // update state
        setPeriodStart(convertToDateTimeLocal(encounter.period.start));
        setEffectiveDateTime(convertToDateTimeLocal(encounter.period.end));
      } else {
        dispatch(
          addEncounter({
            fhirId: null,
            patientFhirId: patient.fhirId,
            isNew: true,
            encounterId: encounterId,
            periodStart: periodStart,
            periodEnd: effectiveDateTime,
          }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <PatientList />
      <Grid item xs={9}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {!selectedPatient ? null : (
            <Grid container direction="row" marginTop={"15px"}>
              <Grid item xs={3} style={{ padding: "10px" }}>
                <TextField
                  id="encounterID"
                  label={t("b2b.addObservation.encounterID")}
                  value={encounterId}
                  variant={isLoading ? "filled" : "outlined"}
                  disabled={isLoading}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEncounterID(e.target.value);
                  }}
                  onBlur={handleEncounterChange}
                />
              </Grid>
              <Grid item xs={3} style={{ padding: "10px" }}>
                <TextField
                  id="periodStart"
                  label={t("b2b.addObservation.periodStart")}
                  type="datetime-local"
                  variant={isLoading ? "filled" : "outlined"}
                  disabled={isLoading}
                  InputLabelProps={{ shrink: true }}
                  value={periodStart}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPeriodStart(e.target.value);
                  }}
                />
              </Grid>
              <Grid container direction="row">
                <Grid item xs={3} style={{ padding: "10px" }}>
                  <Box style={{ position: "relative", minHeight: "70px" }}>
                    <TextField
                      label={t("b2b.addObservation.searchTest")}
                      value={searchQuery}
                      variant={isLoading ? "filled" : "outlined"}
                      disabled={isLoading || !patient}
                      onChange={handleSearchChange}
                      onKeyPress={handleKeyPress}
                      fullWidth
                    />
                    {searchQuery && (
                      <div
                        style={{
                          position: "absolute",
                          top: "60px",
                          width: "100%",
                          zIndex: 1000,
                        }}
                      >
                        <List
                          sx={{
                            width: "auto",
                            bgcolor: "background.paper",
                          }}
                        >
                          {filteredTests.map((test, index) => (
                            <ListItem
                              button
                              key={index}
                              onClick={() =>
                                handleAddTest(selectedPatient, test.LOINC_ID)
                              }
                            >
                              <ListItemText
                                primary={test.INTERNAL_ID + " " + test.TEST}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isLoading ? <CircularProgress /> : <ObservationList />}
        </Grid>
      </Grid>

      <Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // This will space out the child elements to the extreme ends
            p: 1, // Padding for spacing around the buttons
            position: "fixed", // Fix position to the bottom of the screen
            bottom: 0,
            left: 0,
            right: 0, // Stretch across the entire width
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate("/createpatient")}
          >
            {t("b2b.addObservation.createOrEditPatient")}
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate("/submitobservation")}
            disabled={Object.keys(patients).length === 0} // Example condition, adjust as needed
          >
            {t("b2b.addObservation.reviewAndSubmit")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
