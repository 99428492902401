import type { Observation } from "../../store/patientB2BSlice";
import {
  splitOperatorNumericInput,
  SplitResult,
} from "../../utils/referenceValue";
import { convertToDateTimeLocal } from "../../utils/convertToDateTimeLocal";
import {
  createFhirReferenceRange,
  convertFhirToReferenceRange,
} from "./referenceRange";

export const createFhirObservation = (
  observation: Observation,
  loinc: string,
  patientFhirId: string,
  encounterId: string,
): any => {
  // convert observation times from string 2023-09-03T17:09 to iso
  const effectiveTimeISO = new Date(observation.effectiveTime).toISOString();

  const issuedTimeISO = new Date(observation.issuedTime).toISOString();

  // comparators are not allowed in referenceRange, the default is <= for low and >= for high
  // for cases with < or > we need to split the operator and value and make value a float with several decimal places
  // see https://www.hl7.org/fhir/observation-definitions.html#Observation.referenceRange

  const updatedRanges = observation.referenceRange?.map((range) => {
    // split operator and adjust value for low
    const lowVRLimit: SplitResult = splitOperatorNumericInput(
      String(range.low) || "",
    );

    // if comparator is > or <, adjust the value slightly
    const lowVRLimitNumber = lowVRLimit.number;

    if (lowVRLimit.operator === "<" && lowVRLimitNumber !== null) {
      lowVRLimit.number = lowVRLimitNumber - 0.0000001;
    } else if (lowVRLimit.operator === ">" && lowVRLimitNumber !== null) {
      lowVRLimit.number = lowVRLimitNumber + 0.0000001;
    } else {
      lowVRLimit.number = lowVRLimitNumber;
    }

    // split operator and adjust value for high
    const highVRLimit: SplitResult = splitOperatorNumericInput(
      String(range.high) || "",
    );

    const highVRLimitNumber = highVRLimit.number;
    if (highVRLimit.operator === "<" && highVRLimitNumber !== null) {
      highVRLimit.number = highVRLimitNumber - 0.0000001;
    } else if (highVRLimit.operator === ">" && highVRLimitNumber !== null) {
      highVRLimit.number = highVRLimitNumber + 0.0000001;
    } else {
      highVRLimit.number = highVRLimitNumber;
    }

    // return the updated range
    return {
      ...range,
      low: lowVRLimit.number,
      high: highVRLimit.number,
    };
  });

  observation = {
    ...observation,
    referenceRange: updatedRanges,
  };

  const observationResource: any = {
    resourceType: "Observation",
    meta: {
      profile: [
        "http://www.saude.gov.br/fhir/r4/StructureDefinition/BRDiagnosticoLaboratorioClinico-3.2.1",
      ],
    },
    status: "final",
    category: [
      {
        coding: [
          {
            system:
              "http://www.saude.gov.br/fhir/r4/CodeSystem/BRSubgrupoTabelaSUS",
            code: "0202",
            display: "Diagnóstico em laboratório clínico",
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: "http://loinc.org",
          code: loinc,
          display: observation.name,
        },
      ],
      text: observation.name,
    },
    subject: {
      reference: "Patient/" + patientFhirId,
      identifier: {
        system:
          "http://www.saude.gov.br/fhir/r4/StructureDefinition/BRIndividuo-1.0",
        value:
          "http://www.saude.gov.br/fhir/r4/StructureDefinition/BRIndividuo-1.0",
      },
    },
    effectiveDateTime: effectiveTimeISO, // blood drawn
    issued: issuedTimeISO, // result issued
    performer: [
      {
        reference: "Organization/b1307e26-12aa-4849-84f9-3af9a55d5313",
        type: "Organization",
        identifier: {
          system:
            "http://www.saude.gov.br/fhir/r4/StructureDefinition/BREstabelecimentoSaude-1.0",
          value:
            "http://www.saude.gov.br/fhir/r4/StructureDefinition/BREstabelecimentoSaude-1.0",
        },
      },
    ],
    method: {
      text: observation.method,
    },
    specimen: {
      reference: "Specimen/" + observation.specimen.fhirId, // not sure if this is needed
    },
    encounter: {
      reference: `Encounter/${encounterId}`,
    },
  };

  // add fhirId if it exists
  if (observation.fhirId) {
    observationResource.id = observation.fhirId;
  }

  // add reference ranges
  if (observation.referenceRange) {
    observationResource.referenceRange = observation.referenceRange.map(
      (range) => createFhirReferenceRange(range, observation.unit),
    );
  }

  // add results
  if (
    !observation.referenceRange ||
    !observation.referenceRange.some(
      (range) => range.low !== undefined || range.high !== undefined,
    )
  ) {
    // if we don't have reference ranges low and high, we assume we must have text.
    // If we detect one low/high, it MUST be a numeric result
    // TODO in the future loinc code should dictate if result is string, numeric, qualitative...
    observationResource.valueString = observation.result;
  } else {
    if (observation.result === undefined || observation.result === null) {
      throw new Error(
        "Observation result is required for numeric or qualitative types",
      );
    }

    observationResource.valueQuantity = {
      value: parseFloat(observation.result.toString()), // convert result to decimal
      ...(observation.unit && {
        unit: observation.unit, // string
        system: "http://unitsofmeasure.org",
        code: observation.unit, // code //TODO check correct value
      }),
    };
  }

  // add note if it exists
  if (observation.comment) {
    observationResource.note = [
      {
        text: observation.comment,
      },
    ];
  }

  return observationResource;
};

export const convertFhirToObservation = (
  fhirObservation: any,
): { loincId: string; observation: Observation } => {
  const loincId: string = fhirObservation.code.coding[0].code;
  const observation: Observation = {
    fhirId: fhirObservation.id,
    isNew: false,
    name: fhirObservation.code.text,
    effectiveTime: convertToDateTimeLocal(fhirObservation.effectiveDateTime),
    issuedTime: convertToDateTimeLocal(fhirObservation.issued),
    method: fhirObservation.method?.text,
    specimen: fhirObservation.specimen?.reference.split("/")[1],
    type: fhirObservation.valueString ? "string" : "numeric",
    unit: fhirObservation.valueQuantity?.unit,
    result: fhirObservation.valueString || fhirObservation.valueQuantity?.value,
    comment: fhirObservation.note?.[0]?.text,
  };

  // add ReferenceRange
  observation.referenceRange = fhirObservation.referenceRange?.map(
    (range: any) => convertFhirToReferenceRange(range),
  );

  return { loincId: loincId, observation };
};
