import { updateSelectedObservation } from "../activeDataB2C";
import { StratifiedReferenceRange } from "../../store/rawDataB2C";

function updateSelectedObservationThunk(
  selectedObservation: number,
  observationUnit: string,
  referenceValues: StratifiedReferenceRange,
  rawPatientData: number[],
  unit: string,
) {
  let axisRange: number[] = [0, 0];
  const minFromVR = referenceValues?.min as number | null;
  const maxFromVR = referenceValues?.max as number | null;

  // set axis range
  // we need to filter out null values from the rawPatientData to avoid null being converted to zero
  const minFromData = Math.min(
    ...Object.values(rawPatientData).filter((value) => value !== null),
    minFromVR !== null ? minFromVR : Infinity,
  );
  const maxFromData = Math.max(
    ...Object.values(rawPatientData),
    minFromVR ?? 0,
    maxFromVR ?? 0,
  );

  const roundDown = Math.floor(minFromData * 0.8);
  const roundUp = Math.ceil(maxFromData * 1.2);

  if (unit === "Absolute") {
    axisRange = [roundDown, roundUp];
  }

  if (unit === "% from reference") {
    axisRange = [-200, 200];
  }

  return (dispatch: any) => {
    dispatch(
      updateSelectedObservation({
        selectedObservation,
        observationUnit,
        axisRange,
      }),
    );
  };
}

export default updateSelectedObservationThunk;
