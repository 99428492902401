import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import Header from "./pages/Header/Header";
import { b2cRoutes } from "./pages/b2c/b2cRoutes";
import { b2bRoutes } from "./pages/b2b/b2bRoutes";
import { getPatientData, getStatisticsData } from "./store/rawDataB2C";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { callApi } from "./services/MsalApiCall";

const AppRouter = () => {
  const [isB2B, setIsB2C] = useState(false);
  const dispatch = useAppDispatch();
  const selectedVisit = useAppSelector(
    (state) => state.rawDataB2C.settings.visit,
  );

  useEffect(() => {
    const fetchData = async () => {
      const { response } = await callApi();
      if (response?.account?.idTokenClaims?.extension_Roles === "Admin") {
        setIsB2C(true);
      } else {
        setIsB2C(false);
        const { accessToken } = await callApi();
        // here we can trigger the API call to get the user's data
        dispatch(getPatientData(accessToken));
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchStatisticsData = async () => {
      const { accessToken } = await callApi();
      if (selectedVisit) {
        dispatch(getStatisticsData({ accessToken, visit: selectedVisit }));
      }
    };

    fetchStatisticsData();
  }, [selectedVisit, dispatch]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Header />,
      //TODO errorElement: <ErrorPage />,
      // loader: rootLoader,
      // action: rootAction,
      children: isB2B ? b2bRoutes : b2cRoutes,
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
