import React from "react";
import Card from "../../components/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

interface Element {
  title: string;
  description: string;
  uri: string;
}

function Overview() {
  const { t } = useTranslation();
  const elements: Element[] = [
    {
      title: t("b2b.overview.searchpatient.title"),
      description: t("b2b.overview.searchpatient.description"),
      uri: "/searchpatient",
    },
    {
      title: t("b2b.overview.createpatient.title"),
      description: t("b2b.overview.createpatient.description"),
      uri: "/createpatient",
    },
    {
      title: t("b2b.overview.addobservation.title"),
      description: t("b2b.overview.addobservation.description"),
      uri: "/addobservation",
    },
  ];
  return (
    <div>
      <Box sx={{ flexGrow: 1, margin: "50px" }}>
        <h1>{t("b2b.overview.yourAccount")}</h1>
        <Grid
          container
          columns={{ xm: 6, sm: 6, md: 6, lg: 6, xl: 12 }}
          spacing={2}
        >
          {elements.map((element, index) => (
            <Grid item key={index}>
              <Card
                key={index}
                title={element.title}
                content={element.description}
                uri={element.uri}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default Overview;
