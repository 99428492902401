import React from "react";
import Card from "../../components/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

interface Element {
  title: string;
  description: string;
  uri: string;
}

function Overview() {
  const { t } = useTranslation();
  const elements: Element[] = [
    {
      title: t("overview.dashboard.title"),
      description: t("overview.dashboard.description"),
      uri: "/dashboard",
    },
    // {
    //   title: t("overview.resultsHistory.title"),
    //   description: t("overview.resultsHistory.description"),
    //   uri: "/overview/myhistory",
    // },
    // {
    //   title: t("overview.loginSecurity.title"),
    //   description: t("overview.loginSecurity.description"),
    //   uri: "/overview/mysettings",
    // },
    {
      title: t("overview.customerService.title"),
      description: t("overview.customerService.description"),
      uri: "/overview/customerservice",
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, margin: "50px" }}>
      <h1>{t("b2c.overview.yourAccount")}</h1>
      <Grid
        container
        columns={{ xm: 6, sm: 6, md: 6, lg: 6, xl: 12 }}
        spacing={2}
      >
        {elements.map((element, index) => (
          <Grid item key={index}>
            <Card
              key={index}
              title={element.title}
              content={element.description}
              uri={element.uri}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Overview;
