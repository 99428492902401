export const convertAgeSpecToDays = (
  ageSpec: string,
): { operator: string | null; days: number | null } => {
  if (ageSpec === "NB") return { operator: "", days: 0 };
  const match = ageSpec.match(/^([<>]=?)?(\d+)([DY])$/);
  if (!match) return { operator: null, days: null };

  const [, operator, num, unit] = match;
  const number = parseInt(num, 10);
  const days = unit === "D" ? number : number * 365;

  return { operator, days };
};
