export const countries = [
  {
    code: "en",
    name: "English",
    country_code: "en-us",
  },
  {
    code: "pt",
    name: "Português",
    country_code: "pt-br",
  },
];
