import React from "react";
import En from "../../images/flags/en.svg";
import De from "../../images/flags/de.svg";
import Pt from "../../images/flags/pt.svg";

interface FlagIconProps {
  language: string;
}

const flagMap: { [key: string]: string } = {
  // flag icon from https://nucleoapp.com/svg-flag-icons
  en: En,
  de: De,
  pt: Pt,
};

const FlagIcon: React.FC<FlagIconProps> = ({ language }) => {
  const flagSrc = flagMap[language];

  if (!flagSrc) {
    return null;
  }

  return (
    <img
      src={flagSrc}
      alt={`${language} flag`}
      style={{ width: "90%", height: "100%" }}
    />
  );
};

export default FlagIcon;
