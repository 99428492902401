import { createSlice, PayloadAction, nanoid } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { handlePatientRequest } from "../services/fhir"; // Import the API service
import { createFhirPatient } from "..//types/fhir/patient"; // Import the API service
import { handleObservationRequest } from "../services/fhir";

export interface Specimen {
  fhirId: string;
  code: string;
  display: string;
}

export interface ReferenceRange {
  low?: number | null; // old referenceRangeLow
  high?: number | null; // old referenceRangeHigh
  textNegative?: string | null; // text negative should have a single vlaue
  // textPositive?: string | null; // text positive can have multiple values, the service provider defines. One of them will be in Observation.result, and should not be passed in ReferenceRanges
  sex?: string | null;
  ageMin?: string | null; //TODO age can be days, years.. today we are considering only days
  ageMax?: string | null; //TODO age can be days, years.. today we are considering only days
  status?: string;
  statusCode?: string;
}

export interface Observation {
  fhirId: string | null; // id of the observation
  isNew: boolean;
  name: string; // name of the observation
  method?: string; // method as provided by the sp
  source?: string; // source that types the observation
  result?: string | number | boolean; // result of the observation
  effectiveTime: string; // time when blood was collected
  issuedTime: string; // time when result was issued
  specimen: Specimen;
  unit?: string;
  referenceRange?: ReferenceRange[];
  comparator?: "<" | "<=" | ">" | ">=";
  type: string; //TODO type should be derived from loinc codes, as we do not store type in observation
  comment?: string | null;
  error?: string | null;
}

export interface Encounter {
  fhirId: string | null;
  isNew: boolean | null;
  encounterId: string; // service ID coming from client
  patientFhirId: string;
  periodStart: string;
  periodEnd: string;
  error?: string | null;
}

export interface Patient {
  fhirId: string | null;
  isNewPatient: boolean | null;
  nameGiven: string;
  nameFamily: string;
  identifierValue: string;
  identifierType: string; // default codes in fhirTemplate
  phone: string; // mascara (XX) XXXXX-XXXX
  email: string; // needs validation
  sex: string;
  birthDate: string; // format "1974-12-25"
  addressStreet: string;
  addressStreetNumber: string;
  addressNeighborhood: string; // bairro
  state: string; // must follow BRDivisaoGeograficaBrasil
  city: string; // cannot find code, maybe BR npm package has cities?
  country: string; // ISO 3166 3 letter code
  postalCode: string; // mascara XXXXX-XXXX
  observations: { [key: string]: Observation[] }; // not sure what system to use, check fhir documentation
  encounter: Encounter;
  error?: string | null;
}

interface PatientState {
  patients: { [key: string]: Patient };
  selectedPatient: string | null;
  error: string | null;
}

const initialState: PatientState = {
  patients: {},
  selectedPatient: null,
  error: null,
};

export const createNewPatientThunk = createAsyncThunk(
  "patientData/createNewPatient",
  async ({
    accessToken,
    patient,
  }: {
    accessToken: string;
    patient: Patient;
  }) => {
    const response = handlePatientRequest(
      createFhirPatient(patient),
      "POST",
      accessToken,
    );

    // set patient.fhirId to the response.id
    const data = await response;
    patient.fhirId = data.id;

    return patient;
  },
);

export const removeObservationThunk = createAsyncThunk(
  "patientData/removeObservation",
  async (
    {
      observation,
      patientId,
      observationId,
      accessToken,
    }: {
      observation: Observation;
      patientId: string;
      observationId: string;
      accessToken: string;
    },
    { dispatch },
  ) => {
    // Delete the observation from the FHIR database
    const method = "DELETE";
    if (!observation.isNew) {
      await handleObservationRequest(
        method,
        accessToken,
        observation,
        "not needed", // not needed when deleting
        "not needed", // not needed when deleting
        "not needed", // not needed when deleting
      );
    }

    // Remove the observation from the Redux state
    dispatch(removeObservation({ patientId, observationId }));
  },
);

export const patientSlice = createSlice({
  name: "patientData",
  initialState,
  reducers: {
    addPatient: {
      reducer(
        state: any,
        action: PayloadAction<{ id: string; patient: Patient }>,
      ) {
        const { id, patient } = action.payload;
        // Ensure the patients object exists in the state
        if (!state.patients) {
          state.patients = {};
        }
        // Add the patient using the key
        state.patients[id] = patient;
      },
      prepare(patient: Patient): { payload: { id: string; patient: Patient } } {
        return {
          payload: {
            id: nanoid(),
            patient: {
              ...patient,
              observations: patient.observations || {}, // Ensure observations is included
            },
          },
        };
      },
    },
    removePatient: (state: any, action: PayloadAction<string>) => {
      const patientId = action.payload;
      delete state.patients[patientId];
    },
    updatePatient: (
      state: any,
      action: PayloadAction<{ patientId: string; Patient: Patient }>,
    ) => {
      const { patientId, Patient } = action.payload;
      const patient = state.patients[patientId];
      if (patient) {
        state.patients[patientId] = { ...patient, ...Patient };
      }
    },
    choosePatient(state, action: PayloadAction<string | null>) {
      state.selectedPatient = action.payload;
    },
    // Reducer to clear the selected patient
    clearSelectedPatient(state) {
      state.selectedPatient = null;
    },
    removeAllObservationsForPatient: (state, action: PayloadAction<string>) => {
      const patientId = action.payload;
      const patient = state.patients[patientId];
      if (patient) {
        patient.observations = {};
      }
    },
    removeObservation: (
      state,
      action: PayloadAction<{ patientId: string; observationId: string }>,
    ) => {
      const { patientId, observationId } = action.payload;
      const patient = state.patients[patientId];
      if (patient) {
        delete patient.observations[observationId];
      }
    },
    addObservation: {
      reducer(
        state: any,
        action: PayloadAction<{
          patientId: string;
          observationId: string;
          observation: Observation;
        }>,
      ) {
        const { patientId, observationId, observation } = action.payload;
        const patient = state.patients[patientId];
        if (patient) {
          // Check if the observation with the same id already exists to avoid duplicates
          const existingObservation = patient.observations[observationId];
          if (!existingObservation) {
            // Add the new observation with only the id filled
            patient.observations[observationId] = [];
          }
        }

        if (patient) {
          if (observation) {
            patient.observations[observationId] = observation;
          }
        }
      },
      prepare(
        patientId: string,
        observationId: string,
        observation: Observation,
      ): {
        payload: {
          patientId: string;
          observationId: string;
          observation: Observation;
        };
      } {
        return {
          payload: {
            patientId: patientId,
            observationId: observationId,
            observation: {
              ...observation,
            },
          },
        };
      },
    },
    updateObservation: (
      state: any,
      action: PayloadAction<{
        patientId: string;
        observationId: string;
        updatedObservation: Partial<Observation>;
      }>,
    ) => {
      const { patientId, observationId, updatedObservation } = action.payload;
      const patient = state.patients[patientId];
      if (patient) {
        const observation = patient.observations[observationId];
        if (observation) {
          patient.observations[observationId] = {
            ...observation,
            ...updatedObservation,
          };
        }
      }
    },
    resetPatientsState(state: PatientState) {
      state.patients = {};
      state.selectedPatient = null;
    },
    addEncounter(state, action: PayloadAction<Encounter>) {
      const encounter = action.payload;
      if (state.selectedPatient) {
        state.patients[state.selectedPatient].encounter = encounter;
      }
    },
    // given a patient ID only, clear all observation.results for this patient
    clearObservationResultsForPatient(
      state: any,
      action: PayloadAction<string>,
    ) {
      const patientId = action.payload;
      const patient = state.patients[patientId];
      if (patient) {
        Object.keys(patient.observations).forEach((observationId) => {
          const observations = patient.observations[observationId];
          if (observations) {
            observations.result = undefined;
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewPatientThunk.fulfilled, (state, action) => {
        const patient = action.payload;
        if (patient.fhirId) {
          state.patients[nanoid()] = patient;
        } else {
          state.error =
            "Failed to create new patient: fhirId not returned when creating new Patient.";
        }
        state.error = null;
      })
      .addCase(createNewPatientThunk.rejected, (state, action) => {
        state.error = action.error.message || "Failed to create new patient";
      });
  },
});

export const {
  addPatient,
  removePatient,
  updatePatient,
  choosePatient,
  clearSelectedPatient,
  addObservation,
  removeAllObservationsForPatient,
  removeObservation,
  updateObservation,
  resetPatientsState,
  addEncounter,
  clearObservationResultsForPatient,
} = patientSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// export const incrementAsync = (amount: number) => (dispatch: any) => {
//   setTimeout(() => {
//     // This is the async operation
//     dispatch(incrementByAmount(amount));
//   }, 1000); // For example, after a 1 second delay
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPatients = (state: any) => state.patients?.patients ?? [];
export const selectChoosePatient = (state: any) =>
  state.patients?.selectedPatient;

export default patientSlice.reducer;
