import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import i18next from "../../i18n";
import { countries } from "../../config/defaults";
import FlagIcon from "./FlagIcon";

export default function CountryDropdown() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (countryCode: string) => {
    i18next.changeLanguage(countryCode);
    // close menu
    handleClose();
  };

  return (
    <div>
      <Button
        id="country-button"
        aria-controls={open ? "country-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <FlagIcon language={i18next.language} />
      </Button>
      <Menu
        id="country-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "country-button",
        }}
      >
        {countries.map((country) => (
          <MenuItem
            key={country.code}
            onClick={() => handleLanguageChange(country.code)}
            disabled={i18next.resolvedLanguage === country.code}
          >
            <Typography textAlign="center">{country.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
