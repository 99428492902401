import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import InputMask from "@mona-health/react-input-mask";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Dispatch } from "@reduxjs/toolkit";
import {
  addPatient,
  updatePatient,
  selectPatients,
  choosePatient,
  selectChoosePatient,
  createNewPatientThunk,
  Patient,
  Encounter,
  clearObservationResultsForPatient,
} from "../../../store/patientB2BSlice";
import {
  checkValidEmail,
  updateIsEditingPatient,
  updateIsEmailValid,
} from "../../../store/settingsB2B";
import PatientList from "../../../components/b2b/PatientList";
import { getPatientDataForEmail } from "../../../services/fhir";
import { SelectChangeEvent } from "@mui/material";
import BRTipoDocumentoIndividuo from "../../../types/fhir/BRTipoDocumentoIndividuo.json";
import BRDivisaoGeograficaBrasil from "../../../types/fhir/BRDivisaoGeograficaBrasil.json";
import { callApi } from "../../../services/MsalApiCall";
import { CircularProgress } from "@mui/material";
import { checkEmail } from "../../../utils/checkEmail";
import { useTranslation } from "react-i18next";
import { handlePatientRequest } from "../../../services/fhir"; // Import the API service
import { createFhirPatient } from "../../../types/fhir/patient"; // Import the API service

const BRTipoDocumentoIndividuoValueSet = BRTipoDocumentoIndividuo.compose
  .include[0].concept as any;

const BRDivisaoGeograficaBrasilValueSet =
  BRDivisaoGeograficaBrasil.concept.flatMap((region) => region.concept) as any;

function CreatePatient() {
  const { t } = useTranslation();
  const patients = useAppSelector(selectPatients);
  const dispatch = useAppDispatch();
  const isEmailValid = useAppSelector(
    (state: any) => state.settingsB2B.isEmailValid,
  );
  const selectedPatientId = useAppSelector(selectChoosePatient);

  // patientData
  const [patientData, setPatientData] = useState<Patient>({
    fhirId: null,
    isNewPatient: null,
    nameGiven: "",
    nameFamily: "",
    identifierValue: "",
    identifierType: "TAX", // default codes in fhirTemplate
    phone: "", // mascara (XX) XXXXX-XXXX
    email: "", // needs validation
    sex: "",
    birthDate: "", //format "1974-12-25"
    addressStreet: "",
    addressStreetNumber: "",
    addressNeighborhood: "", // bairro
    state: "43", // must follow BRDivisaoGeograficaBrasil
    city: "", // cannot find code, maybe BR npm package has cities?
    country: "BRA",
    postalCode: "", // mascara XXXXX-XXXX
    observations: {}, // not sure what system to use, check fhir documentation
    encounter: {} as Encounter,
  });

  const [isSearchingEmail, setIsSearchingEmail] = useState(false);

  const updatePatientData = (field: any) => (e: SelectChangeEvent<string>) => {
    setPatientData((prevData) => ({
      ...prevData,
      [field]: e.target.value,
    }));

    // clear observations if patient data changes
    dispatch(clearObservationResultsForPatient(selectedPatientId));
  };

  const navigate = useNavigate();
  const API_BACKEND_URL = process.env.REACT_APP_BACKEND_URL as string;

  // input checks
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isBirthDateValid, setIsBirthDateValid] = useState(true);
  const phoneRegex = /^\(\d{2}\) \d{3} \d{2}-\d{4}$/;

  const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);
  const postalCodeRegex = /^\d{5}-\d{3}$/;

  const validateBirthDate = (birthDateString: string) => {
    const birthDate = new Date(birthDateString);
    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 120);

    if (birthDate > today || birthDate < minDate) {
      setIsBirthDateValid(false);
      return false;
    }
    setIsBirthDateValid(true);
    return true;
  };

  // reset state
  const resetCheckState = () => {
    setIsPhoneValid(true);
    setIsBirthDateValid(true);
    setIsPostalCodeValid(true);
  };

  // clear fields
  // WARNING: needs to be followed by resetCheckState();
  const clearFields = (setPatientData: any, dispatch: Dispatch) => {
    setPatientData({
      fhirId: null,
      isNewPatient: null,
      nameGiven: "",
      nameFamily: "",
      identifierValue: "",
      identifierType: "", // default codes in fhirTemplate
      phone: "", // mascara (XX) XXXXX-XXXX
      email: "", // needs validation
      sex: "",
      birthDate: "", //format "1974-12-25"
      addressStreet: "",
      addressStreetNumber: "",
      addressNeighborhood: "", // bairro
      state: "", // must follow BRDivisaoGeograficaBrasil
      city: "", // cannot find code, maybe BR npm package has cities?
      country: "BR",
      postalCode: "", // mascara XXXXX-XXXX
      observations: {},
      encounter: {} as Encounter,
    });

    dispatch(updateIsEmailValid(false));
  };

  const handlePhoneCheck = () => {
    if (patientData.phone !== "") {
      const isValid = phoneRegex.test(patientData.phone);
      setIsPhoneValid(isValid);
      return isValid;
    }
  };

  const isEditingPatient = useAppSelector(
    (state: any) => state.settingsB2B.isEditingPatient,
  );

  const handlePostalCodeCheck = (e: React.FocusEvent<HTMLInputElement>) => {
    const isValid = postalCodeRegex.test(e.target.value);
    setIsPostalCodeValid(isValid);
  };

  const areAllFieldsFilled = () => {
    // check phone is valid
    const dataToCheck = patientData;
    const isPhoneValid = phoneRegex.test(dataToCheck.phone);

    // check postal code is valid
    const isPostalCodeValid = postalCodeRegex.test(dataToCheck.postalCode);

    // check birth date is valid
    const isBirthDateValid = validateBirthDate(dataToCheck.birthDate);

    if (!isPhoneValid || !isPostalCodeValid || !isBirthDateValid) {
      return false;
    }

    // if isNewPatient is null, clear fields and return alert message
    if (dataToCheck.isNewPatient === null) {
      alert("Error: resetting fields.");
      clearFields(setPatientData, dispatch);
      resetCheckState();
      return false;
    }

    return Object.entries(patientData)
      .filter(
        ([key]) =>
          key !== "observations" && key !== "isNewPatient" && key !== "fhirId",
      )
      .every(([, value]) => value);
  };

  const handleEmailCheck = () => {
    if (!checkEmail(patientData.email)) {
      alert(t("b2b.createPatient.invalidEmailMessage"));
      // clear fields
      clearFields(setPatientData, dispatch);
      resetCheckState();
      setIsSearchingEmail(false);
      return;
    }

    setIsSearchingEmail(true);

    const fetchData = async () => {
      try {
        const { accessToken } = await callApi();
        const data = await getPatientDataForEmail(
          API_BACKEND_URL,
          patientData.email,
          accessToken,
        );

        if (data && data.entry && data.entry.length > 0) {
          const patientFromFHIR = data.entry[0].resource;

          setPatientData({
            ...patientData,
            fhirId: patientFromFHIR?.id,
            isNewPatient: false,
            nameGiven: patientFromFHIR?.name?.[0]?.given?.join(" ") || "",
            nameFamily: patientFromFHIR?.name?.[0]?.family || "",
            identifierValue: patientFromFHIR?.identifier?.[0]?.value || "",
            identifierType:
              patientFromFHIR?.identifier?.[0]?.type?.coding?.[0]?.code || "",
            phone: patientFromFHIR?.telecom?.[0]?.value || "",
            sex: patientFromFHIR?.gender || "",
            birthDate: patientFromFHIR?.birthDate || "",
            addressStreet: patientFromFHIR?.address?.[0]?.line?.[0] || "",
            addressStreetNumber: patientFromFHIR?.address?.[0]?.line?.[1] || "",
            addressNeighborhood: patientFromFHIR?.address?.[0]?.line?.[2] || "",
            state: patientFromFHIR?.address?.[0]?.state || "",
            city: patientFromFHIR?.address?.[0]?.city || "",
            country: patientFromFHIR?.address?.[0]?.country || "",
            postalCode: patientFromFHIR?.address?.[0]?.postalCode || "",
            observations: {},
          });

          // reset validation
          resetCheckState();
        } else {
          // if no patient is found, set isNewPatient to true
          setPatientData({
            ...patientData,
            isNewPatient: true,
          });
        }
        dispatch(checkValidEmail(patientData.email));

        setIsSearchingEmail(false);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  };

  useEffect(() => {
    if (selectedPatientId) {
      // Fetch patient data based on patientId
      // This is a placeholder, replace with your actual data fetching logic
      const patient = patients[selectedPatientId];

      setPatientData({
        ...patient,
        observations: { ...patient.observations },
      });
    } else {
      clearFields(setPatientData, dispatch);
      resetCheckState();
    }
  }, [selectedPatientId, patients, dispatch, setPatientData]);

  // add an api to check if email exists in database, and if yes populate the fields

  const handleAddPatient = async () => {
    const patient = patients[selectedPatientId || ""];
    const { accessToken } = await callApi();
    if (patientData.isNewPatient === false) {
      if (!isEditingPatient) {
        dispatch(addPatient(patientData));
      } else {
        dispatch(
          updatePatient({
            patientId: selectedPatientId,
            Patient: {
              ...patientData,
              observations: patient?.observations
                ? { ...patient.observations }
                : {},
            },
          }),
        );
      }
      handlePatientRequest(createFhirPatient(patientData), "PUT", accessToken);
      choosePatient(null);
    } else {
      if (isEditingPatient) {
        dispatch(
          updatePatient({
            patientId: selectedPatientId,
            Patient: {
              ...patientData,
              observations: patient?.observations
                ? { ...patient.observations }
                : {},
            },
          }),
        );
        handlePatientRequest(
          createFhirPatient(patientData),
          "PUT",
          accessToken,
        );
      } else {
        dispatch(
          createNewPatientThunk({
            accessToken,
            patient: patientData,
          }),
        );
      }
    }

    // clear fields
    clearFields(setPatientData, dispatch);
    resetCheckState();
    if (patient) {
      navigate("/createpatient");
      dispatch(choosePatient(null));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <PatientList />
        <Grid item xs={9}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90vh",
                  "& > :not(style)": { m: 1, width: "33ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  {t("b2b.createPatient.personalInformation")}
                </Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <TextField
                    id="email"
                    label={t("b2b.createPatient.email")}
                    variant={selectedPatientId ? "filled" : "outlined"}
                    value={patientData.email}
                    disabled={selectedPatientId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPatientData({ ...patientData, email: e.target.value })
                    }
                    onBlur={handleEmailCheck}
                    fullWidth
                  />
                  {isSearchingEmail && (
                    <CircularProgress size={30} style={{ marginLeft: 10 }} />
                  )}
                  {isEmailValid && (
                    <span role="img" aria-label="checkmark">
                      ✅
                    </span>
                  )}
                </Box>

                <FormControl variant="outlined">
                  <InputLabel id="identifierType-label">
                    Document Type
                  </InputLabel>
                  <Select
                    labelId="identifierType-label"
                    id="identifierType"
                    label={t("b2b.createPatient.documentType")}
                    disabled={!isEmailValid || isSearchingEmail}
                    variant={
                      !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                    }
                    value={patientData.identifierType}
                    onChange={updatePatientData("identifierType")}
                  >
                    {BRTipoDocumentoIndividuoValueSet.map((concept: any) => (
                      <MenuItem key={concept.code} value={concept.code}>
                        {concept.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="identifierValue"
                  label={t("b2b.createPatient.documentNumber")}
                  disabled={!isEmailValid || isSearchingEmail}
                  variant={
                    !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                  }
                  value={patientData.identifierValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePatientData("identifierValue")(e)
                  }
                />
                <TextField
                  id="nameGiven"
                  label={t("b2b.createPatient.name")}
                  value={patientData.nameGiven}
                  disabled={!isEmailValid || isSearchingEmail}
                  variant={
                    !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePatientData("nameGiven")(e)
                  }
                />
                <TextField
                  id="nameFamily"
                  label={t("b2b.createPatient.surname")}
                  disabled={!isEmailValid || isSearchingEmail}
                  variant={
                    !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                  }
                  value={patientData.nameFamily}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePatientData("nameFamily")(e)
                  }
                />
                <FormControl variant="outlined">
                  <InputLabel id="sex-label">
                    {t("b2b.createPatient.sex")}
                  </InputLabel>
                  <Select
                    disabled={!isEmailValid || isSearchingEmail}
                    variant={
                      !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                    }
                    labelId="sex-label"
                    id="sex"
                    label="sex"
                    value={patientData.sex}
                    onChange={updatePatientData("sex")}
                  >
                    <MenuItem value="male">
                      {t("b2b.createPatient.male")}
                    </MenuItem>
                    <MenuItem value="female">
                      {t("b2b.createPatient.female")}
                    </MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  variant="body1"
                  // reduce margin to zero
                  sx={{ textAlign: "center" }}
                >
                  <Typography
                    variant="body1"
                    // reduce margin to zero
                    sx={{ textAlign: "center" }}
                  >
                    {t("b2b.createPatient.phoneNumber")}
                  </Typography>
                </Typography>
                <InputMask
                  mask="(99) 999 99-9999"
                  value={patientData.phone}
                  disabled={!isEmailValid || isSearchingEmail}
                  maskChar=" "
                  alwaysShowMask={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updatePatientData("phone")(e);
                    handlePhoneCheck;
                  }}
                  onBlur={handlePhoneCheck}
                  style={{
                    color: isPhoneValid ? "black" : "red",
                    borderColor: isPhoneValid ? "inherit" : "red",
                    borderWidth: isPhoneValid ? "1px" : "2px",
                    height: "45px",
                    borderRadius: "3px",
                    backgroundColor:
                      isEmailValid && !isSearchingEmail
                        ? "white"
                        : "rgba(0, 0, 0, 0.11)",
                  }}
                />
                <TextField
                  id="birthDate"
                  label={t("b2b.createPatient.birthDate")}
                  disabled={!isEmailValid || isSearchingEmail}
                  variant={
                    !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                  }
                  sx={{ input: { color: isBirthDateValid ? "black" : "red" } }}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={patientData.birthDate}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updatePatientData("birthDate")(e);
                  }}
                  inputProps={{
                    max: new Date().toISOString().split("T")[0], // Use inputProps to set the max attribute
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90vh",
                  "& > :not(style)": { m: 1, width: "30ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  {t("b2b.createPatient.address")}
                </Typography>

                <TextField
                  id="addressStreet"
                  label={t("b2b.createPatient.street")}
                  disabled={!isEmailValid || isSearchingEmail}
                  variant={
                    !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                  }
                  value={patientData.addressStreet}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePatientData("addressStreet")(e)
                  }
                />
                <TextField
                  id="addressStreetNumber"
                  label={t("b2b.createPatient.streetNumber")}
                  disabled={!isEmailValid || isSearchingEmail}
                  variant={
                    !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                  }
                  value={patientData.addressStreetNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePatientData("addressStreetNumber")(e)
                  }
                />
                <TextField
                  id="addressNeighborhood"
                  label={t("b2b.createPatient.neighborhood")}
                  disabled={!isEmailValid || isSearchingEmail}
                  variant={
                    !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                  }
                  value={patientData.addressNeighborhood}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePatientData("addressNeighborhood")(e)
                  }
                />
                <TextField
                  id="city"
                  label={t("b2b.createPatient.city")}
                  disabled={!isEmailValid || isSearchingEmail}
                  variant={
                    !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                  }
                  value={patientData.city}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePatientData("city")(e)
                  }
                />
                <FormControl variant="outlined">
                  <InputLabel id="identifierType-label">State</InputLabel>
                  <Select
                    labelId="state"
                    id="state"
                    label={t("b2b.createPatient.state")}
                    disabled={!isEmailValid || isSearchingEmail}
                    variant={
                      !isEmailValid || isSearchingEmail ? "filled" : "outlined"
                    }
                    value={patientData.state}
                    onChange={updatePatientData("state")}
                  >
                    {BRDivisaoGeograficaBrasilValueSet.map((concept: any) => (
                      <MenuItem key={concept.code} value={concept.code}>
                        {concept.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  {t("b2b.createPatient.postalCode")}
                </Typography>

                <InputMask
                  mask="99999-999"
                  value={patientData.postalCode}
                  disabled={!isEmailValid || isSearchingEmail}
                  maskChar=" "
                  alwaysShowMask={true}
                  InputMask
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updatePatientData("postalCode")(e);
                    handlePostalCodeCheck(
                      e as unknown as React.FocusEvent<HTMLInputElement>,
                    );
                  }}
                  onBlur={handlePostalCodeCheck}
                  style={{
                    color: isPostalCodeValid ? "black" : "red",
                    borderColor: isPostalCodeValid ? "inherit" : "red",
                    borderWidth: isPostalCodeValid ? "1px" : "2px",
                    height: "45px",
                    borderRadius: "3px",
                    backgroundColor:
                      isEmailValid && !isSearchingEmail
                        ? "white"
                        : "rgba(0, 0, 0, 0.11)",
                  }}
                />
                <TextField
                  id="country"
                  label={t("b2b.createPatient.country")}
                  // disable
                  disabled={true}
                  variant="outlined"
                  value={"BRA"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePatientData("country")(e)
                  }
                />
                <Button
                  variant="contained"
                  disabled={!isEmailValid}
                  onClick={() => {
                    if (areAllFieldsFilled()) {
                      handleAddPatient();
                      dispatch(updateIsEditingPatient(false));
                    } else {
                      alert(t("b2b.createPatient.fillFieldsMessage")); // You can replace this with a more sophisticated feedback mechanism
                    }
                  }}
                  color={isEditingPatient ? "success" : "primary"}
                >
                  {isEditingPatient
                    ? t("b2b.createPatient.finishEditingButton")
                    : t("b2b.createPatient.addPatientButton")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right", // This will space out the child elements to the extreme ends
              p: 1, // Padding for spacing around the buttons
              position: "fixed", // Fix position to the bottom of the screen
              bottom: 0,
              left: 0,
              right: 0, // Stretch across the entire width
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate("/addobservation")}
              disabled={Object.keys(patients).length === 0 || isEditingPatient} // Example condition, adjust as needed
            >
              {t("b2b.createPatient.addObservationButton")}
            </Button>
          </Box>
        </Grid>
        {patients.error && (
          <div>
            <p>{patients.error}</p>
          </div>
        )}
      </Grid>
    </>
  );
}

export default CreatePatient;
