import { convertNumberToOperatorAndNumber } from "./convertNumberToOperatorAndNumber";

export type ValidateInputTestType = {
  type: string;
  minNumeric: string | null;
  maxNumeric: string | null;
  textNegative: string | null;
  textPositive: string | null;
};

export default function validateInput(
  value: string,
  type: string,
  testInput: ValidateInputTestType | null,
): boolean[] {
  let isValid = false;
  let isOutOfRange = false;

  // transform testInput to lower case and "numerico" to "numeric"
  type = type.toLowerCase();

  let numberValue = value;

  // if result is string, return true if value does not match textNegative
  if (type === "string" && testInput) {
    // check that value is in textNegative or textPositive
    isValid =
      value === testInput.textPositive || value === testInput.textNegative;
    isOutOfRange = value !== testInput.textNegative;

    // early return if value is not equal to textNegative
    return [isValid, isOutOfRange];
  }

  if (type === "numerico") {
    type = "numeric";
  }

  if (type === "numeric" || type === "qualitative") {
    // transformation comma to dot only necessary for numerical results in  (FHIR does not accept ,)
    numberValue = numberValue.replace(/,/g, ".");

    const numberPattern = /^-?\d+(\.\d+)?$/;

    // check if value is a valid number
    isValid = numberPattern.test(numberValue);

    // if testInput is not null, check if value is within range
    // Check range only if it's a valid number
    if (testInput && isValid) {
      const { operator: operatorVRMin, number: referenceValueMin } =
        convertNumberToOperatorAndNumber(testInput.minNumeric);
      const { operator: operatorVRMax, number: referenceValueMax } =
        convertNumberToOperatorAndNumber(testInput.maxNumeric);

      // Set default operators if they are null
      const finalOperatorMin = operatorVRMin === null ? "<" : operatorVRMin;
      const finalOperatorMax = operatorVRMax === null ? ">" : operatorVRMax;

      if (referenceValueMin !== null) {
        if (finalOperatorMin === "<" || finalOperatorMin === ">") {
          if (parseFloat(numberValue) < referenceValueMin) {
            isOutOfRange = true;
          }
        }

        if (finalOperatorMin === "<=" || finalOperatorMin === ">=") {
          if (parseFloat(numberValue) <= referenceValueMin) {
            isOutOfRange = true;
          }
        }
      }
      if (referenceValueMax !== null) {
        if (finalOperatorMax === ">" || finalOperatorMin === "<") {
          if (parseFloat(numberValue) > referenceValueMax) {
            isOutOfRange = true;
          }
        }

        if (finalOperatorMax === ">=" || finalOperatorMin === "<=") {
          if (parseFloat(numberValue) >= referenceValueMax) {
            isOutOfRange = true;
          }
        }
      }
    }
  }

  // Add the rest of your validation logic here

  return [isValid, isOutOfRange];
}
